export  const globalurl=process.env.REACT_APP_HOST


export function request(suburl:string, options:any ) {

    var optionsbody = options.body
    const newOptions = {   ...options }
    newOptions.headers = {
        Accept: 'application/json, text/plain, */*',
        ...newOptions.headers
    }
    if (newOptions.method === 'POST'  ) {
        newOptions.body = JSON.stringify(optionsbody)
    }
    const url =   globalurl+suburl
    return fetch(url, newOptions)
        .then(response => {
            if (!options.check) {
                return response.json()
            } else {
                return response.text()
            }
        }).catch(e => {
            const status = e.name
            if (status === 401) {
            }
            return {
                code: -1
            }
        })
}

export async function fetchRankList(params:any) {
    console.info('fetchRankList', params)
    return request('/api/query_rank', {
        method: 'POST',
        body: params
    })
}

export async function fetchDetail(params:any) {
    console.info('fetchDetail', params)
    return request('/api/query_brief', {
        method: 'POST',
        body: params
    })
}