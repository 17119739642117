import React from 'react';
import './App.less';
import rank1 from './res/rank1.png';
import rank2 from './res/rank2.png';
import rank3 from './res/rank3.png';
import facebook from './res/icon_facebook.png';
import twitch from './res/icon_twitch.png';
import youtube from './res/icon_youtube.png';
import vip from './res/icon_vip.png';
import viphead from './res/icon_vip_head.png';
import nohead from './res/nohead.png';
import button_rule from './res/button_rule.png';
import {Layout, message, Card, Tag, Table, Menu, Popover, Radio, Space} from "antd";
import {fetchDetail, fetchRankList} from "./api";
import {Typography} from 'antd';
import {AlignType} from 'rc-table/lib/interface';

const {Title} = Typography;
const {Header, Sider, Content} = Layout;
const timesOption = ['All time', 'This Week', 'Today']
const timesOptiondir = new Map([['This Week', 2], ['Today', 1], ['All time', 3]])
var games = [
    'Olympwnks',
    'Pwnk Royale',
    'Pwnk Character',
    'Pwnk Brand',
    'Pwnk Spotting',
    'Pwnk FastFocus',
    'Pwnk Morphing',
    'Pwnk Who',
    'Pwnk Nation',
    'Shanghai Poker',
    'Pwnk Rescue',
    'Pwnk Draw',
    'Pwnk 90s',
    'Pwnk Actor',
    'Pwnk Animal',
    'Pwnk Loot',
    'Pwnk Mandela',
    'Pwnk Man',
    'NFT Garden',
    'Pwnk Food',
    'Pwnk Products',
    'Pwnk Defense',
    'Pwnk Life',
    'Pwnk GameWorld',
    'Pwnk Crossdress',
    'Pwnk Places',
    'Pwnk Relations',
    'Pwnk Raid',
    'Pwnk Snack',
    'Pwnk Doll',
    'Pwnk Biltz',
    'Pwnk Count',
    'Pwnk Away',
    'Pwnk Ball'
]

const gamesdir = new Map([
    ["Pwnk Ball", 1],
    ["Pwnk Defense", 2],
    ["Pwnk Raid", 3],
    ["Pwnk Snack", 4],
    ["Pwnk Doll", 5],
    ["Pwnk Biltz", 6],
    ["Pwnk Count", 8],
    ["Pwnk Royale", 9],
    ["Pwnk Away", 10],
    ["Pwnk Places", 11],
    ["Pwnk Relations", 12],
    ["Pwnk Life", 13],
    ["Pwnk GameWorld", 14],
    ["Pwnk Crossdress", 15],
    ["Olympwnks", 20],
    ["Pwnk Loot", 22],
    ["Pwnk Mandela", 23],
    ["Pwnk Man", 17],
    ["Pwnk Draw", 21],
    ["Pwnk Rescue", 25],
    ["Shanghai Poker", 24],
    ["Pwnk 90s", 26],
    ["Pwnk Actor", 27],
    ["Pwnk Animal", 28],
    ["NFT Garden", 16],
    ["Pwnk Food", 19],
    ["Pwnk Products", 18],
    ["Pwnk Who", 29],
    ["Pwnk Nation", 30],
    ["Pwnk Morphing", 33],
    ["Pwnk Spotting", 34],
    ["Pwnk FastFocus", 35],
    ["Pwnk Brand", 36],
    ["Pwnk Character", 37],
])

class Home extends React.Component {

    dataSource: any[] = [];
    defaultgame = games[0]
    defaultTime = timesOption[1]
    state = {
        loading: false,
        dataSource: []
    };

    onradioChange = (e: any) => {
        if (this.state.loading) {
            message.error("Dont click too fast")
            return
        }
        this.defaultTime = e.target.value

        console.info(window.location.pathname.split("/")[0] + "/" + this.defaultTime + "/" + this.defaultgame)
        var url = window.location.pathname.split("/")[0] + "/" + this.defaultTime.replace(" ", "") + "/" + this.defaultgame.replace(" ", "")
        // @ts-ignore
        this.props.history.push(url)
        this.search()
    };
    menuClick = (e: any) => {
        if (this.state.loading) {
            message.error("Dont click too fast")
            return
        }
        this.defaultgame = e.key
        var url = window.location.pathname.split("/")[0] + "/" + this.defaultTime.replace(" ", "") + "/" + this.defaultgame.replace(" ", "")
        // @ts-ignore
        this.props.history.push(url)
        this.search()
    };

    componentWillMount() {
        const path = window.location.pathname.replace("%20", " ")
        const paths = path.split("/")
        console.info(paths)
        if (paths.length === 3) {
            var pre = paths[1]
            var game = paths[2]
            if (game.startsWith("Pwnk")) {
                game = game.replace("Pwnk", "Pwnk ")
            }
            if (game.startsWith("NFT")) {
                game = game.replace("NFT", "NFT ")
            }
            if (game.startsWith("Shanghai")) {
                game = game.replace("Shanghai", "Shanghai ")
            }
            pre = pre.replace("ThisWeek", "This Week").replace("Alltime", "All time")
            if (timesOption.indexOf(pre) > -1 && games.indexOf(game) > -1) {
                this.defaultgame = game
                this.defaultTime = pre
            }
        }
    }

    componentDidMount() {
        this.search()
    }

    async search() {
        this.dataSource = []
        this.setState({
            loading: true
        })
        var gameid: any = gamesdir.get(this.defaultgame)
        const res = await fetchRankList(
            Object.assign({}, {
                rank_type: timesOptiondir.get(this.defaultTime),
                sub_type: gameid,
                start: 1,
                count: 100
            })
        )
        console.info(res)
        if (res.code == 0) {
            if (res.data.totalCount === undefined) {
                this.setState({
                    loading: false
                })
                return
            }
            var uids = []
            var cnt = 0
            var uidsandrankIndex = new Map()
            var uidsandscore = new Map()
            var num = res.data.rankListInfo.length
            for (var i in res.data.rankListInfo) {
                cnt += 1
                var content = res.data.rankListInfo[i]
                var key = content.key
                var score = content.score
                uids.push({"uid": key})
                uidsandrankIndex.set(key, content.rankIndex)
                uidsandscore.set(key, score)
                if (uids.length === 20 || (cnt === num)) {
                    const eachres = await fetchDetail(
                        Object.assign({}, {
                            "brief_ts_infos": uids
                        })
                    )
                    if (res.code == 0) {
                        try {
                            for (var j in eachres.data.playerBriefInfos) {
                                var briefInfo = eachres.data.playerBriefInfos[j]
                                var platform = briefInfo.playerInfo.accountSystemInfo.platformType
                                if (platform == undefined) {
                                    name = "dev0"
                                } else {
                                    var name = briefInfo.playerInfo.accountSystemInfo.thirdPartyPlatformInfoMap[platform].platformDisplayname
                                }
                                var uid = briefInfo.uid
                                var rankIndex = uidsandrankIndex.get(uid)
                                let livePlatformAccountInfo = briefInfo.livePlatformAccountInfo;
                                var avatar = livePlatformAccountInfo.profileImage
                                var maxScore = uidsandscore.get(uid)
                                console.info(1111, maxScore)
                                if (this.defaultgame != "NFT Garden") {
                                    if (this.defaultTime === 'This Week') {
                                        var scores = briefInfo.playerInfo.rankSystemInfo.gameMaxScoreInfo.weekMax
                                    } else if (this.defaultTime === 'All time') {
                                        var scores = briefInfo.playerInfo.rankSystemInfo.gameMaxScoreInfo.historyMax
                                    } else {
                                        var scores = briefInfo.playerInfo.rankSystemInfo.gameMaxScoreInfo.dailyMax
                                    }

                                    // var maxScore = scores.maxScore
                                    var player
                                    if (scores != undefined) {
                                        player = scores.details[gameid].viewerName
                                    }
                                }
                                var liveurl = livePlatformAccountInfo.liveUrl
                                var vipInfoMap = briefInfo.playerInfo.roleSystemInfo.vipInfoMap
                                var vip = false
                                for (var each in vipInfoMap) {
                                    var info = vipInfoMap[each]
                                    if (Number(info.vipExpireTimestamp) > Date.parse(new Date().toString()) / 1000) {
                                        vip = true
                                        break
                                    }
                                }
                                this.dataSource.push({
                                    "Ranking": rankIndex,
                                    "Name": name,
                                    "avatar": avatar,
                                    "Score": maxScore,
                                    "Player": player,
                                    "platform": platform,
                                    "liveurl": liveurl,
                                    "vip": vip
                                })
                            }
                        } catch (e) {
                            console.error(e)
                            message.error("Some Data Has Error")
                            // this.setState({
                            //     loading: false
                            // })
                            // return
                        }
                    } else {
                        message.error("System Error")
                        this.setState({
                            loading: false
                        })
                        return
                    }
                    uids = []
                }
            }
        } else {
            message.error("System error")
        }
        console.info('111dataSource', this.state.dataSource)
        this.setState({
            loading: false
        })

    }

    render() {

        const menudisable = this.state.loading
        console.info(menudisable)
        const dataSource = this.dataSource.slice(0, 100)
        const {loading} = this.state;

        console.info('dataSource', dataSource, this.defaultgame, this.defaultTime)
        var columns
        if (this.defaultgame == "NFT Garden") {
            columns = [
                {
                    title: 'Ranking',
                    dataIndex: 'Ranking',
                    key: 'Ranking',
                    width: "15%",
                    render(line: any, record: any, val: any) {
                        val = val + 1
                        if (val == 1) {
                            return <img
                                className="img"
                                src={rank1}
                            />
                        }
                        if (val == 2) {
                            return <img
                                className="img"
                                src={rank2}
                            />
                        }
                        if (val == 3) {
                            return <img
                                className="img"
                                src={rank3}
                            />
                        }
                        return <span className="span">{val}</span>
                    }

                },
                {
                    title: 'Name',
                    dataIndex: 'Name',
                    key: 'Name',
                    width: "55%",
                    render(val: any, all: any) {
                        var icon, vipicon, vipheadicon, headpic
                        if (all.platform == 1) {
                            icon = twitch
                        } else if (all.platform == 2
                        ) {
                            icon = facebook
                        } else if (all.platform == 3
                        ) {
                            icon = youtube
                        }
                        if (all.vip) {
                            vipicon = vip
                            vipheadicon = viphead
                        }
                        var imageClick = (e: any) => {
                            console.log('Click!!!!', e);
                            if (undefined !== e) {
                                window.open(e, "_blank");
                            }
                        }
                        if (all.avatar == undefined) {
                            headpic = nohead
                        } else {
                            headpic = all.avatar
                        }
                        var head
                        return <div className="nameandpic"><img height={34} className="avatarpic"
                                                                src={headpic}></img>
                            <div className="platformimg"><img height={17} onClick={imageClick.bind(this, all.liveurl)}
                                                              src={icon}/>

                            </div>
                            <div className="vipimg">

                                <img height={12}
                                     src={vipicon}/>
                            </div>
                            <div className="vipheadimg">

                                <img height={35}
                                     src={vipheadicon}/>
                            </div>
                            <span className="name">{val}</span>
                        </div>
                    }
                },
                {
                    title: 'Total Revenue',
                    dataIndex: 'Score',
                    key: 'Score',
                    width: "25%",
                    sorter: (a: any, b: any) => a.Score - b.Score,
                    align: "center" as AlignType,
                    render(val: any) {
                        return <span className="tag">{val}</span>
                    }

                }
            ];
        } else {
            columns = [
                {
                    title: 'Ranking',
                    dataIndex: 'Ranking',
                    key: 'Ranking',
                    width: "15%",
                    render(line: any, record: any, val: any) {
                        val = val + 1
                        if (val == 1) {
                            return <img
                                className="img"
                                src={rank1}
                            />
                        }
                        if (val == 2) {
                            return <img
                                className="img"
                                src={rank2}
                            />
                        }
                        if (val == 3) {
                            return <img
                                className="img"
                                src={rank3}
                            />
                        }
                        return <span className="span">{val}</span>
                    }

                },
                {
                    title: 'Name',
                    dataIndex: 'Name',
                    key: 'Name',
                    width: "30%",
                    render(val: any, all: any) {
                        var icon, vipicon, vipheadicon, headpic
                        if (all.platform == 1) {
                            icon = twitch
                        } else if (all.platform == 2
                        ) {
                            icon = facebook
                        } else if (all.platform == 3
                        ) {
                            icon = youtube
                        }
                        if (all.vip) {
                            vipicon = vip
                            vipheadicon = viphead
                        }
                        var imageClick = (e: any) => {
                            console.log('Click!!!!', e);
                            if (undefined !== e) {
                                window.open(e, "_blank");
                            }
                        }
                        if (all.avatar == undefined) {
                            headpic = nohead
                        } else {
                            headpic = all.avatar
                        }
                        var head
                        return <div className="nameandpic"><img height={34} className="avatarpic"
                                                                src={headpic}></img>
                            <div className="platformimg"><img height={17} onClick={imageClick.bind(this, all.liveurl)}
                                                              src={icon}/>

                            </div>
                            <div className="vipimg">

                                <img height={12}
                                     src={vipicon}/>
                            </div>
                            <div className="vipheadimg">

                                <img height={35}
                                     src={vipheadicon}/>
                            </div>
                            <span className="name">{val}</span>
                        </div>
                    }
                },
                {
                    title: 'Player',
                    dataIndex: 'Player',
                    key: 'Player',
                    width: "25%",
                    align: "center" as AlignType,
                    render(val: any) {
                        return <span className="name">{val}</span>
                    }
                },
                {
                    title: 'Top Score',
                    dataIndex: 'Score',
                    key: 'Score',
                    width: "25%",
                    sorter: (a: any, b: any) => a.Score - b.Score,
                    align: "center" as AlignType,
                    render(val: any) {
                        return <span className="tag">{val}</span>
                    }

                }
            ];
        }
        if (this.defaultTime == "All time") {
            var gamesmenu = games.filter(game => game != "NFT Garden");
        } else {
            var gamesmenu = games
        }
        return (
            <Layout>
                <Header className="App-header">
                    <div className="headerdiv">
                        <span className="headerspan">
                            LEADERBOARDS <Popover
                            content={(<div className="rulediv">
                                <Title level={5}>Streamer leaderboards are ranked based on the top high scores earned by
                                    streamers' viewers.
                                </Title>
                                <Title level={5}>Daily leaderboards reset at 4 AM UTC, while weekly leaderboards reset
                                    at 4 AM UTC each Monday.</Title>
                                <Title level={5}>If scores are tied, whoever got the score earlier will rank
                                    higher.</Title>
                            </div>)}
                            title={(<Title level={4}>Leaderboards' Rules: </Title>)}
                            trigger="click"
                        ><img height={35} src={button_rule}/>

                </Popover> </span></div>
                </Header>
                <Layout>
                    <Sider width='320' className='divsider'>
                        <div className='divsider'>
                            <Card bordered={false}> <Radio.Group className='Radio' buttonStyle="solid"
                                                                 options={timesOption}
                                                                 onChange={this.onradioChange} value={this.defaultTime}
                                                                 optionType="button"/> </Card>
                            <Menu className='main' defaultSelectedKeys={[this.defaultgame]}
                                  onClick={this.menuClick}
                                  mode="inline"
                            >

                                {gamesmenu.map(item => (
                                    <Menu.Item className="Menu" key={item} disabled={menudisable}>
                                        <div className="menudiv"><span>{item}</span></div>
                                    </Menu.Item>
                                ))}
                            </Menu>


                        </div>
                    </Sider>
                    <Content> <Card bordered={false}><Table loading={loading}
                                                            dataSource={dataSource} columns={columns}
                                                            pagination={false}/> </Card></Content>
                </Layout>
            </Layout>
        );
    }
}

export default Home;
